<template>
  <div class="content">
    <div class="ng-cloak container_body">
      <article class="link_container_body">
        <h1>联系我们</h1>
        <div class="article-content">
          <h2>一、我有建议</h2>
          <p>
            如果您对本网站提供的内容和服务有什么意见和建议，或者您发现本网站提供的内容和服务方面存在文字、图片、附件和格式等错误，请您畅所留言!
          </p>
          <h2>二、我有疑问</h2>
          <p>如果您还有其他问题，请与我们联系，非常感谢您的理解和支持！</p>
          <p>主办单位：{{ zbf }}</p>
          <p>联系电话：{{ systemphone }}</p>
          <p>地址：{{ address }}</p>
          <p>邮编：{{ youbian }}</p>
          <h2>工作时间</h2>
          <p>上午：{{ gzTimeAM }},下午：{{ gzTimePM }}</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName,
      address: window.CONFIG.APP_INFO.address,
      systemphone: window.CONFIG.APP_INFO.systemphone,
      zbf: window.CONFIG.APP_INFO.zbf,
      youbian: window.CONFIG.APP_INFO.youbian,
      gzTimeAM: window.CONFIG.APP_INFO.gzTimeAM,
      gzTimePM: window.CONFIG.APP_INFO.gzTimePM
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/link.scss';
.content {
  min-height: 500px;
}
</style>
